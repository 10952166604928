<template>
  <BasicModal
    :action="submitForm"
    :close-on-action="false"
    action-title="Apply for access"
    :show-action-button="!form.isSubmitted"
    :show-cancel-button="!form.isSubmitted"
    :disable-action-button="form.isLoading || !isFormValid"
  >
    <template v-if="!form.isSubmitted">
      <h5>Request API access</h5>
      <p class="mb-6">
        The Dispatch API is currently in beta testing. Interested in testing?
      </p>
      <FormSubmitErrors :formName="formName" />
      <FormInput :formName="formName" fieldName="dispatchApiUseCase" ref="focusElement" />
      <LoadingOverlay v-if="form.isLoading" />
    </template>
    <template v-else>
      <div class="success-container">
        <img src="@/assets/images/planet.png" />
        <h4>Thank you!</h4>
        <p>
          We’ve received your request. Please check your email for updates on
          the API.
        </p>
      </div>
    </template>
  </BasicModal>
</template>

<script>

  import { mapState } from 'vuex'

  import useForm from '@/composables/useForm'
  import useRecaptcha from '@/composables/useRecaptcha'

  import FormInput from '@/components/forms/FormInput.vue'
  import BasicModal from '@/components/modals/_BasicModal.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'
  import FormSubmitErrors from '@/components/forms/FormSubmitErrors.vue'

  export default {
    components: {
      FormInput,
      BasicModal,
      LoadingOverlay,
      FormSubmitErrors,
    },
    setup() {

      // data
      const formName = 'apiKeyRequestForm'

      // composables
      const { form, isFormValid, focusElement } = useForm({ formName })
      const { setRecaptchaToken } = useRecaptcha({ formName, action: 'api_key_request' })

      return {
        form,
        formName,
        isFormValid,
        focusElement,
        setRecaptchaToken,
      }

    },
    computed: {
      ...mapState('user', ['user']),
    },
    methods: {
      submitForm() {
        return this.setRecaptchaToken()
          .then(() => {
            return this.$store.dispatch('forms/SUBMIT_FORM', this.formName)
          })
          .catch(() => {
            // do nothing, template will show error
          })
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .success-container
    @apply flex
    @apply flex-col
    @apply items-center

</style>
